import Vue from "vue";
import App from "./App.vue";
import Element from "element-ui";
import router from "./router";
import "element-ui/lib/theme-chalk/index.css";
import VueClipBorad from "vue-clipboard2";
Vue.use(Element);
Vue.use(VueClipBorad);
Vue.config.productionTip = false;

const bus = new Vue();
Vue.prototype.$bus = bus;
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("access_token");
  if (to.path === "/login") {
    return next();
  }
  if (!token) {
    return next("/login");
  }
  if (to.path === from.path && to.path !== "/") {
    // 如果目标路径与当前路径相同，避免导航
    console.log("避免导航到当前路径:", to.path);
    next(false);
  } else {
    // 继续导航
    next();
  }
});
new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
}).$mount("#app");
