<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      isMobile: window.innerWidth <= 768,
    };
  },
  provide() {
    return {
      isMobile: this.isMobile,
    };
  },
  created() {
    window.addEventListener("resize", this.checkIsMobile);
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style>
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  /* margin-top: 60px; */
  overflow: overlay;
}
</style>

<style>
html,
body {
  overflow: overlay;
  height: 100%;
  /* 文字风格 Sans-serif 各笔画粗细相同，Serif 笔画粗细不同，monospace 等宽体，cursive草书，fantasy梦幻 */
  font-family: "Microsoft YaHei", sans-serif, "Helvetica Neue", Helvetica, Arial,
    "黑体", "宋体", Arial;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 14px;
  color: #333;
}

/* 重置各标签的默认样式 */
a,
body,
center,
cite,
code,
dd,
del,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hr,
html,
img,
input,
label,
legend,
li,
mark,
ol,
p,
section,
span,
textarea,
time,
td,
th,
ul {
  margin: 0;
  border: 0;
  padding: 0;
  font-style: normal;
  box-sizing: border-box;
  /*  自动换行 */
  word-wrap: break-word;
  /*  强制英文单词断行 */
  word-break: break-all;
}
* {
  box-sizing: border-box;
}
/*  设置标签为块级分类 */
article,
aside,
details,
fieldset,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

/* 去除input标签的默认样式 */
button,
input,
textarea {
  -webkit-appearance: none;
  font-family: "Microsoft YaHei", sans-serif, "Helvetica Neue", Helvetica, Arial,
    "黑体", "宋体", Arial;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1em;
  outline: none;
  background-color: transparent;
}

/*  禁止多文本框手动拖动大小 */
textarea {
  resize: none;
  -webkit-appearance: none;
}

/* 去掉按下的阴影盒子 */
input,
textarea,
a {
  -webkit-tap-highlight-color: transparent;
}

/*  清除a标签下划线 */
a,
a:visited {
  text-decoration: none;
}
a:focus,
a:active,
a:hover {
  outline: none;
}

/*  清除列表前面的点 */
ol,
li,
ul {
  list-style: none;
}

/*  清除IE下图片的边框 */
img {
  border-style: none;
  font-size: 0;
}

/*  解决chrome浏览器默认黄色背景问题 */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

/*  设置默认滚动条样式 */
::-webkit-input-placeholder {
  color: #afbdcc;
}
:-moz-placeholder {
  color: #afbdcc;
}
::-moz-placeholder {
  color: #afbdcc;
}
:-ms-input-placeholder {
  color: #afbdcc;
}
::-webkit-scrollbar {
  /* width: 6px; */
  /* height: 6px; */
}
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
::-webkit-scrollbar-track-piece {
  background-color: #f5f5f5;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 6px;
}
::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  scrollbar-width: none;
  height: 0;
}
::-webkit-resizer {
  background-repeat: no-repeat;
  background-position: bottom right;
}
.b {
  display: flex;
}

.lr {
  flex-direction: row;
}

.rl {
  flex-direction: row-reverse;
}

.tb {
  flex-direction: column;
}

.bt {
  flex-direction: column-reverse;
}

.pc {
  justify-content: center;
}

.ps {
  justify-content: flex-start;
}

.pe {
  justify-content: flex-end;
}

.pb {
  justify-content: space-between;
}

.pa {
  justify-content: space-around;
}

.ac {
  align-items: center;
}

.as {
  align-items: flex-start;
}

.ae {
  align-items: flex-end;
}

.cc {
  align-items: center;
  justify-content: center;
}

.as {
  align-self: flex-start;
}

.ae {
  align-self: flex-end;
}

.ast {
  align-self: stretch;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.flex {
  flex: 1;
}

.grow {
  flex-grow: 1;
}

.shrink {
  flex-shrink: 1;
}

.no-grow {
  flex-grow: 0;
}

.no-shrink {
  flex-shrink: 0;
}
</style>
